import React, { useContext } from "react";
import { Link } from "react-router-dom";
import bg from './bg.jpg';

import { CatalogContext } from "../../contexts";

const Bareware = () => {
  const { midBanners } = useContext(CatalogContext);
  const mbs = midBanners.filter((mb,index)=>index<3)
  return (
  <div style={{background:"#023666", padding:"20px"}} className="section">
 <div className="container ">
   
   <div className="row">
   {mbs.map((mb, index)=>(
    
    <div className="col-4">
    <Link to={mb.link}>
      <img src={mb.image} alt="" className="img-fluid" />
    </Link>
    
    </div>
   ))
   
   }
     
   </div>
 
   </div>
  </div>
   
  
  
  );
};

export default Bareware;
