import React from "react";
import { Link } from "react-router-dom";

export const DropDown = ({ classDrop }) => {
  return (
    <div id="drop_menu" className={classDrop}>
      <div className="row p-0">
     
        <div className="col-12 pl-10 text-left drop-down-2">
          <Link to="/categories/92">
            <h6 className="font-14">Adjustable Handle (1D,2D,3D)</h6>
          </Link>
          <Link to="/categories/96">
            <h6 className="font-14">Cafeteria Accessories</h6>
          </Link>
          <Link to="/categories/95">
            <h6 className="font-14">Chair Bushes, Shoes, Cap</h6>
          </Link>
          <Link to="/categories/76">
            <h6 className="font-14">Fixed Handle</h6>
          </Link>
          <Link to="/categories/79">
            <h6 className="font-14">Institutional Furniture Parts</h6>
          </Link>
          <Link to="/categories/64">
            <h6 className="font-14">Net Back</h6>
          </Link>
          <Link to="/categories/97">
            <h6 className="font-14">PU Foam</h6>
          </Link>
          
          <Link to="/categories/93">
            <h6 className="font-14">PU Pad & PP Pad</h6>
          </Link>
          <Link to="/categories/98">
            <h6 className="font-14">Seat Cover & Accessories</h6>
          </Link>
         
          <Link to="/categories/70">
            <h6 className="font-14">Wheel</h6>
          </Link>
         
        </div>
      </div>
    </div>
  );
};
