import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import { Link } from "react-router-dom";
import { CatalogContext, UserContext } from "../../contexts";
import Loading from "../common/Loading";
import httpService from "../../services/httpService";

const Register = () => {
  const [otp, setOtp] = useState();
  const { signup, user, log } = useContext(UserContext);
  const [registration, setRegistration] = useState({
    firstname: "",
    lastname: "",
    email: "",
    telephone: "",
    agree: 1,
    fax: "",
    password: "",
  });
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const { showToast } = useContext(CatalogContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (log === 1) {
      console.log("here");
      navigate("/account");
    }
  }, [user, log]);
  const handleChange = (e) => {
    const form = { ...registration };
    form[e.target.name] = e.target.value;
    setRegistration(form);
  };

  const register = async () => {
    setButtonsDisabled(true);
    if (Number(registration.password) !== Number(otp)) {
      showToast("error", "Incorrect Otp", "");
      setButtonsDisabled(false);
      return;
    }
    const keys = Object.keys(registration);
    let check = true;
    for (let i = 0; i < keys.length; i++) {
      if (
        registration[keys[i]] === "" &&
        keys[i] !== "fax" &&
        keys[i] !== "email"
      ) {
        showToast("error", `${keys[i]} is required!`, "");
        setButtonsDisabled(false);
        return false;
      }
    }

    let registerForm = { ...registration };
    if (registerForm.fax === "") {
      delete registerForm.fax;
    }

    delete registerForm.password;

    const response = await signup(registerForm);
    console.log("response", response);
    if (response && "success" in response && response.success === 1) {
      console.log("Account created", registration);
      showToast("success", "Account Created Successfully!", "");
      navigate("/home");
    } else {
      setButtonsDisabled(false);
      console.log("Sign up failed!", response);
      showToast("error", response.error[0], "");
    }
  };

  const getOtp = () => {
    setButtonsDisabled(true);
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("mobile_number", registration.telephone);
        const { data: response } = await httpService.post(
          httpService.apiEndpoint + "feed/rest_api/get_otp",
          formData,
          { headers: { ...httpService.headers } }
        );
        if (response && "success" in response && response.success === 1) {
          console.log(response.otp);
          setOtp(response.otp);
          setButtonsDisabled(false);
        } else {
          showToast("error", "Please try again later!", "");
          setButtonsDisabled(false);
        }
      } catch (error) {
        showToast("error", "Please try again later!", "");
        setButtonsDisabled(false);
      }
    };
    if (registration.telephone && registration.telephone.length >= 10) {
      if (registration.firstname.length < 3 || registration.lastname < 3) {
        showToast("error", "Invalid First ot last name", "");
      } else {
        fetchData();
      }
    } else {
      showToast("error", "Invalid mobile number", "");
    }
    setButtonsDisabled(false);
  };
  return (
    <>
      <Header />
      {log === 0 ? (
        <div>
          <div className="section mh-70">
            <div className="row">
              <div className="col-12">
                {!otp ? (
                  <div className="login-card text-center login-box">
                    <h1>Register Account</h1>
                    <p className="text-description mb-0">
                      Register with us for exclusive benefits.
                    </p>
                    <input
                      onChange={(e) => handleChange(e)}
                      type="text"
                      name="firstname"
                      value={registration.firstname}
                      placeholder="First Name"
                      className="form-control "
                    />
                    <input
                      onChange={(e) => handleChange(e)}
                      type="text"
                      name="lastname"
                      value={registration.lastname}
                      placeholder="Last Name"
                      className="form-control "
                    />
                    <input
                      onChange={(e) => handleChange(e)}
                      type="email"
                      name="email"
                      value={registration.email}
                      placeholder="Email address"
                      className="form-control "
                    />
                    <input
                      onChange={(e) => handleChange(e)}
                      type="number"
                      name="telephone"
                      value={registration.telephone}
                      placeholder="Phone"
                      className="form-control "
                    />
                    <input
                      onChange={(e) => handleChange(e)}
                      type="text"
                      name="fax"
                      value={registration.fax}
                      placeholder="Reference Number"
                      className="form-control "
                    />
                    <button
                      onClick={getOtp}
                      disabled={buttonsDisabled}
                      className="btn btn-primary btn-gold w-100 mt-20 mb-10"
                    >
                      {buttonsDisabled ? "Loading..." : "Sign Up"}
                    </button>
                    <br />
                    Already Registered?{" "}
                    <Link to="../login" className="text-description">
                      Login Now
                    </Link>
                  </div>
                ) : (
                  <div className="login-card text-center login-box">
                    <i
                      onClick={() => {
                        setOtp(undefined);
                        setRegistration({ ...registration, password: "" });
                      }}
                      className="bi bi-arrow-left"
                    ></i>
                    <h1>Register Account</h1>
                    <p className="text-description mb-0">
                      Register with us for exclusive benefits.
                    </p>
                    <input
                      onChange={(e) => handleChange(e)}
                      type="password"
                      name="password"
                      value={registration.password}
                      className="form-control "
                      placeholder="OTP"
                    />
                    <button
                      onClick={register}
                      disabled={buttonsDisabled}
                      className="btn btn-primary btn-gold w-100 mt-20 mb-10"
                    >
                      {buttonsDisabled ? "Loading..." : "Sign Up"}
                    </button>
                    <br />
                    Already Registered?{" "}
                    <Link to="../login" className="text-description">
                      Login Now
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default Register;
