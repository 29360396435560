import React from "react";
import { Link } from "react-router-dom";
import httpService from "../../../services/httpService";

const ProductCard = ({ product }) => {
  // console.log(product);
  return (
    <>
      {product ? (
        <div className="col-sm-4 col-12">
          <Link to={`/product/${product.product_id}`}>
            <div className="card card-styled card-styled-dark text-center mt-20 product-card">
              <img
                src={product.original_image || product.thumb}
                alt=""
                className="img-fluid p-cate-img"
              />
              <div className="row">
                <div className="col-8 text-left">
                <h3 className=" mb-0 mt-10 text-gold">
                <b>{product.name}</b>
              </h3>
              <p className="mb-0 font-18 mt-10">
                {product.price_formated.slice(0, 1) +
                  product.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </p>
                </div>
                <div className="col-4">
                  <button className="btn btn-primary">
                    <i className="bi bi-cart-plus font-16"></i>
                  </button>
                </div>
              </div>
             
            </div>
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default ProductCard;
