import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import {SessionProvider, CatalogProvider, CartProvider, UserProvider} from './contexts'
import { CityProvider } from './contexts/CityContext';

ReactDOM.render(
  <SessionProvider>
    <CityProvider>
    <CatalogProvider>
    <UserProvider>
      
      <CartProvider>
          <App />
        </CartProvider>
      
    </UserProvider>
    </CatalogProvider>
    </CityProvider>
  </SessionProvider>
  ,
  document.getElementById('root')
);


