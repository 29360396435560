import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CatalogContext } from "../../contexts";

const HomeDecor = () => {
  const { homeAndDecor } = useContext(CatalogContext);
  const noTrending = homeAndDecor.filter((hd)=>(hd.category_id!==99));
  return (
    <section className="most-wanted dark-section pt-50 off-bg">
    <div className="container">
      
      <div className="row text-center">
      
        
          <h1>Shop Across Categories</h1>
           <p className="text-description mb-0">
            Wide range of industrial furniture parts to choose from.
          </p>
          
           <div className="row mt-20">
           {noTrending.map((category,index) => 
            <div className="col-4 fhome-box">
            <div className="test_box box-01 " key={index}>
              
                <div
                  className="inner"
                  style={{
                    backgroundImage:
                      `url(${category.image.startsWith('https://admin.ndfurnituremart.com/') ? category.image : 'https://admin.ndfurnituremart.com/' + category.image})`
                  }}
                />
                <div className="text-center">
                  <h1 className="test_title">{category.name}</h1>
                  <Link className="test_link" to={`/categories/${category.category_id}`}>
                    Shop Now
                  </Link>
                </div>
               
            </div>
           </div> 
            )}
           </div>
           
       
      </div>
      <br />
    </div>
  </section>
  
  );
};

export default HomeDecor;
