import React, { useState, useRef, useEffect } from "react";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext, SessionContext } from "../../contexts";
import logo from "../../imgs/logo/logo.png";
import SearchForm from "./SearchForm";
import SideBar from "./SideBar";
import "./header.css";
import { DropDown } from "./DropDown";
import { SelectCity } from "../SelectCity/SelectCity";
import SearchIcon from '@mui/icons-material/Search';
import CityContext from "../../contexts/CityContext";


function showSearch() {
  if (window.innerWidth < 1250) {
    document.getElementById("search-form").style.display = "flex";
  }
}

function menuOpen() {
  if (window.innerWidth < 750) {
    document.getElementById("side-bar").style.left = "0px";
    document.getElementById("sidebar-bg").style.display = "inline";
  }
}

const HeaderWhite = () => {
  const { currency, setCurrency } = useContext(SessionContext);
  const { cart } = useContext(CartContext);
  const {SelectedCity} =useContext(CityContext)
  const [searchTerm, setSearchTerm] = useState("");
  const [dropClass, setDropClass] = useState("drop-menu-hidden");
  const searchInput = useRef(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      navigate(`/search/${searchInput.current.value}`);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (window.innerWidth < 1250) {
      document.getElementById("search-form").style.display = "flex";
    } else if (searchInput.current.value) {
      navigate(`/search/${searchInput.current.value}`);
    }
  };

  const showMenu = () => {
    setDropClass("drop-menu-shown");
  };
  const hideMenu = () => {
    setDropClass("drop-menu-hidden");
  };

  

  return (
    <div className="header-bar header-fixed header-app dark-header">
      <SearchForm />
      <div className="container header-container mt-10">
        <div className="topStrip hide-mob"></div>
        <div className="row d-flex align-items-center">
          <div className="col-2 col-sm-1">
            <Link to="../">
              <img src={logo} alt="" className="img-logo" />
            </Link>
          </div>
          <div className="col-sm-7 col-2">
            <nav className="desktop_nav">
              <span>
                <Link className="golden-color" to="/">
                  Home
                </Link>
              </span>
              <span
                onMouseEnter={showMenu}
                onMouseLeave={hideMenu}
                id="drop_here"
              >
                <Link className="golden-color" to="/">
                  Shop
                </Link>
                <DropDown classDrop={dropClass} />
              </span>
              <span id="drop_here">
                <Link className="golden-color" to="/categories/99">
                  Trending Products
                </Link>
              </span>
              <span>
                <Link className="golden-color" to="/story">
                  About Us
                </Link>
              </span>
              <span>
                <Link className="golden-color" to="/contact">
                  Contact
                </Link>
              </span>
            </nav>
          </div>
          <div className="col-5 col-sm-3"  >

            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
            <input
              ref={searchInput}
              onChange={(e) => handleChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              value={searchTerm}
              type="text"
              placeholder="Search"
              className="search-desktop"
              style={{borderRadius:"10px",padding:"5px"}}
              
            />
            <SearchIcon onClick={handleSearch} style={{ color: "#023666",fontSize:"28px",marginLeft:"5px" }}/>
            </div>
           


            <div>
            <Link to="../Cart" className="golden-color">
              <i
                style={{ color: "#fff", position: "relative",marginTop:"-1px"}}
                className="bi bi-cart3 font-24 float-right mr-10 hide-phone golden-color"
              >
                <div className="cart-badge">{cart ? cart.length : 0}</div>
              </i>
        
            </Link>
            </div>
            
            <div style={{marginLeft:"-10px"}}>
            <Link to="../login" className="golden-color">
              <i
                style={{ color: "#fff",marginTop:"2px" }}
                className="bi bi-person-circle font-25 float-right mr-10 golden-color"
              ></i>
            </Link>
            </div>

            
            
           
           
            </div>
            
          </div>
          <div className="col-sm-1 col-2">
          
            <SelectCity/>
          
          </div>
          
          
        </div>
       
      </div>
      <div className=" col-1" style={{textAlign:"center",marginRight:"10px"}}>
      <i
              style={{ color: "#fff", marginRight: "0px" }}
              onClick={() => menuOpen()}
              className="bi bi-list cursor-pointer font-32 mt-30 float-right hide-desktop golden-color"
            ></i>
           
      <SideBar />
          </div>
      
    </div>
  );
};
export default HeaderWhite;
