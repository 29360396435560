import { createContext, useState, useEffect, useContext } from "react";
import httpService from "../services/httpService";



const CityContext = createContext();


const CityProvider = ({children}) => {
const InitialStoreId=localStorage.getItem("storeId") || 0;

  const InitialSelectedData=()=>{
    getAllCityData(InitialStoreId).then((res)=>{
      console.log("InitialSelectedData======>",res)
      localStorage.setItem("CityImage",res.store_image)
      localStorage.setItem("City",res.store_comment)
      localStorage.setItem("storeId",res.store_id)
      setSelectedCity(res);
  }).catch((err)=>console.log("Error",err));

  
  }

    const [AllCities,setAllCities] = useState([]);
    const [AllCitiesData,setAllCitiesData] = useState([]);
    const [SelectedCity,setSelectedCity] = useState("delhi");
    // const store_id = localStorage.getItem('storeId') ;
    const CityImage = localStorage.getItem("CityImage") || null;
    const storeId = localStorage.getItem("storeId") || null;
    const CityName = localStorage.getItem("City") || null;
    const getCity=async()=>{
        try{
          let CityData = await httpService.get(
            httpService.apiEndpointShort + "stores",
            // allItems,
            { headers: { ...httpService.headers } }
          );
          const Data=CityData.data;
          // console.log("CityData=======>",Data.data);
          return Data.data;
        }
        catch(error){
          console.log("Error In City",error);
        }
        
      }
    const getAllCityData=async(store_id)=>{
        try{
            let CityData = await httpService.get(
              httpService.apiEndpointShort + `stores/${store_id}`,
              // allItems,
              { headers: { ...httpService.headers } }
            );
            const Data=CityData.data;
            // console.log(" Solo =======>CityData",Data.data);
            return Data.data;
          }
          catch(error){
            console.log("Error In City",error);
          }
    }
   
    const handelAllCityData=(Data)=>{
        // console.log("handel",Data)
        setAllCitiesData(prev=>[...prev,Data]);
        if(CityImage === null && storeId === null && CityName === null && Data.store_id === 0){
            
              // console.log("City initialdata Data =====>",Data);
              localStorage.setItem("City",Data.store_comment)
              localStorage.setItem("CityImage",Data.store_image)
              localStorage.setItem("storeId",Data.store_id)
              localStorage.setItem("SelectedStoreData",JSON.stringify(Data));
            }
        // console.log("AllCitiesData after update",AllCitiesData);
    }
    useEffect(()=>{
      // InitialSelectedData();
        getCity().then((res)=>{
            setAllCities(res);
           
            
           
        })
        .catch(error=>{console.log("Error In City",error)})
      },[])

      useEffect(()=>{
        // console.log("All City Data",AllCities);
        AllCities && AllCities.length >0 && AllCities.map((el)=>{
            // console.log("City store id",el.store_id);
            getAllCityData(el.store_id).then((res)=>{
                // console.log("REs",res)
                handelAllCityData(res);
            }).catch((err)=>console.log("Error",err));
           
        });
        
      },[AllCities])

      useEffect(()=>{
        if(InitialStoreId === 0){
          InitialSelectedData()
        }
      },[])

     


  return (
    <CityContext.Provider
      value={{
        AllCities,AllCitiesData,SelectedCity,setSelectedCity
      }}
    >
      {children}
    </CityContext.Provider>
  )
}

export default CityContext;
export { CityProvider };