import React, { useState, useEffect, useContext } from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";
import AddressCard from "./AddressCard";
import httpService from "../../../services/httpService";
import { CatalogContext, SessionContext, UserContext } from "../../../contexts";

const AddressBook = ({
  heading,
  addresses,
  selectAddress,
  confirmAddress,
  buttonsDisabled,
  selectedAddress,
  buttonText,
}) => {
  return (
    <div>
      <Header />
      <div className="section mh-70">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 style={{ marginTop: "160px" }}>{heading}</h1>
              <Link to="../account" className="back_btn fl text-description">
                {" "}
                <i className="bi bi-arrow-left-circle-fill"></i> Go Back
              </Link>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            {addresses
              ? addresses.map((address) => {
                  return (
                    <AddressCard
                      selectAddress={selectAddress}
                      showCheck={
                        selectedAddress === address.address_id ? true : false
                      }
                      key={address.address_id}
                      address={address}
                    />
                  );
                })
              : null}
          </div>
          <br />
          <br />
          <div className="row text-center">
            <div>
              <Link to="/addAddress">
                <button className="btn btn-primary btn-gold">
                  {" "}
                  <i className="bi bi-plus-circle"></i>&nbsp; Add New Address
                </button>
              </Link>
              &nbsp;&nbsp;&nbsp;
              {addresses && addresses.length !== 0 ? (
                <span>
                  <button
                    onClick={(e) => confirmAddress(e)}
                    className="btn btn-primary btn-gold"
                  >
                    {" "}
                    <i className="bi bi-arrow-right"></i>&nbsp;{" "}
                    {buttonsDisabled ? "Processing..." : buttonText}
                  </button>
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <FooterDesktop page="/profile" />
    </div>
  );
};

export default AddressBook;
