import { createContext, useState, useEffect, useContext } from "react";
import http from "../services/httpService"
import { SessionContext } from "./SessionContext";
import CityContext from "./CityContext";

const CatalogContext = createContext();


const CatalogProvider = (props)=>{
    const{currency} = useContext(SessionContext);
    const[topBanners, setTopBanners] = useState([]);
    const[topBannersMobile, setTopBannersMobile] = useState([]);

    const[midBanners, setMidBanners] = useState([]);


    const[mostWantedProducts, setMostWanterProducts] = useState([]);
    const[barWare, setBarware] = useState([]);
    const[homeAndDecor, setHomeAndDecor] = useState([]);
    const[barWareCategory, setBarwareCategory] = useState();
    const[homeAndDecorCategory, setHomeAndDecorCategory] = useState();
    const[jewelleryCategory, setJewelleryCategory] = useState();
    const[officeCategory, setOfficeCategory] = useState();
    const[travelCategory, setTravelCategory] = useState();
    const[allProducts, setAllProducts] = useState([]);
    const [toastStatus,setToastStatus] = useState({
        'type':'error',
        'heading':'Invalid Data',
        'msg':'Invalid Form Data',
        'status':'hide'
      });
      const {SelectedCity} =useContext(CityContext)

const store_id =localStorage.getItem("storeId");
    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
      
          // Pick a remaining element.
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }


    useEffect(()=>{
        const fetchData = async()=>{
        
            const {data:fetchedBarWare} = await http.get(http.apiEndpoint+"feed/rest_api/categories&store_id="+store_id,{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedBarWare == "object" && "success" in fetchedBarWare && fetchedBarWare.success ===1){
                setBarware(shuffle(fetchedBarWare.data).splice(0,8));
            }

            
            const {data:fetchedhomeAndDecor} = await http.get(http.apiEndpoint+"feed/rest_api/categories&store_id="+store_id,{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            
            if(typeof fetchedhomeAndDecor == "object" && "success" in fetchedhomeAndDecor && fetchedhomeAndDecor.success ===1){
                setHomeAndDecor(shuffle(fetchedhomeAndDecor.data));
            }

         
            const {data:fetchedAllProducts} = await http.get(http.apiEndpoint+"feed/rest_api/products&store_id=0&limit=2000",{ headers: { ...http.headers, "X-Oc-Currency": currency } });
            if(typeof fetchedAllProducts == "object" && "success" in fetchedAllProducts && fetchedAllProducts.success ===1){
                setAllProducts(fetchedAllProducts.data);
              
            }
        }
        
        fetchData();
    },[currency,SelectedCity])
    useEffect(()=>{
        const fetchData = async()=>{

          const {data:fetchedMidBanners} = await http.get(http.apiEndpoint+"feed/rest_api/banners&id=12",{ headers: { ...http.headers } });
          if(typeof fetchedMidBanners == "object" && "success" in fetchedMidBanners && fetchedMidBanners.success ===1){
              setMidBanners(fetchedMidBanners.data);
          }

            const {data:fetchedTopBanners} = await http.get(http.apiEndpoint+"feed/rest_api/banners&id=15",{ headers: { ...http.headers } });
            console.log(fetchedTopBanners); 
            
            if(typeof fetchedTopBanners == "object" && "success" in fetchedTopBanners && fetchedTopBanners.success ===1){
               setTopBanners(fetchedTopBanners.data);
            }
           const {data:fetchedTopBannersMobile} = await http.get(http.apiEndpoint+"feed/rest_api/banners&id=9",{ headers: { ...http.headers } });
            if(typeof fetchedTopBannersMobile == "object" && "success" in fetchedTopBannersMobile && fetchedTopBannersMobile.success ===1){
                setTopBannersMobile(fetchedTopBannersMobile.data);
            }
        }
        
        fetchData();
    },[])
    

    const getProductFromProductsList = (product_id) => {
        let result = null;
        console.log(allProducts);

        allProducts.forEach((product, index) => {
          if (Number(product.product_id) === Number(product_id)) {
            result = product;
          }
        });
        return result;
      };


      const showToast = (type,heading,msg) =>{
        const info = {type,heading,msg,status:'show'};
        setToastStatus(info);
        setTimeout(function(){
          setToastStatus({...info,status:'hide'})
        },2500)
      }

    return(
        <CatalogContext.Provider value={{toastStatus,showToast, topBanners, midBanners, topBannersMobile,mostWantedProducts, barWare, homeAndDecor, jewelleryCategory, officeCategory,travelCategory, barWareCategory, homeAndDecorCategory, getProductFromProductsList}}>
            {props.children}
        </CatalogContext.Provider>
    )
}

export {CatalogContext, CatalogProvider};