import React from 'react'
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";

const Terms = () => {
  return (
    <div>
    <Header />
    <section className="mh-70">
      <div className="container mb-30">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="mt-100 ">Terms & Conditions</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
         <p className='mt-20'>Coming Soon</p>

          </div>
        </div>
        
      </div>
    </section>
    <FooterDesktop />
  </div>
  )
}

export default Terms