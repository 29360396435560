import React, { useState, useEffect } from 'react';
import './ProductView.css';

const images = [
  "./Img2/iPhone0.jpg",
  "./Img2/iPhone1.jpg",
  "./Img2/iPhone2.jpg",
  "./Img2/iPhone3.jpg",
  "./Img2/iPhone4.jpg",
  "./Img2/iPhone5.jpg",
];

const Product360 = ({Data}) => {
  const [startX, setStartX] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [autoRotation, setAutoRotation] = useState(true);
  const [startX2, setStartX2] = useState(0);
  const [currentRotation, setCurrentRotation] = useState(0);
  useEffect(() => {
    let intervalId;

    if (autoRotation) {
      intervalId = setInterval(() => {
        setCurrentImageIndex((currentImageIndex + 1) % images.length);
      }, 1000); // Adjust the speed of auto-rotation as needed (higher interval for slower rotation)
    }

    return () => clearInterval(intervalId);
  }, [autoRotation, currentImageIndex]);

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setAutoRotation(false);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setAutoRotation(false);
  };

  const handleMouseUp = (e) => {
    const deltaX = e.clientX - startX;
    if (deltaX > 50) {
      setCurrentImageIndex(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
    } else if (deltaX < -50) {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }
    setAutoRotation(false);
  };

  const handleTouchEnd = (e) => {
    const deltaX = e.changedTouches[0].clientX - startX;
    if (deltaX > 50) {
      setCurrentImageIndex(currentImageIndex === 0 ? images.length - 1 : currentImageIndex - 1);
    } else if (deltaX < -50) {
      setCurrentImageIndex((currentImageIndex + 1) % images.length);
    }
    setAutoRotation(false);
  };
// .............................................................
  

  const handleMouseMove2 = (e) => {
    const deltaX = e.clientX - startX;
    setCurrentRotation(currentRotation + deltaX + 20);
    setStartX(e.clientX);
  };

  const handleMouseDown3 = (e) => {
    setStartX(e.clientX);
    document.addEventListener('mousemove', handleMouseMove3);
    document.addEventListener('mouseup', handleMouseUp);
  };
  
  const handleMouseMove3 = (e) => {
    const deltaX = e.clientX - startX;
    setCurrentRotation(currentRotation + deltaX + 20);
    setStartX(e.clientX);
  };
  
  const handleMouseUp3 = () => {
    document.removeEventListener('mousemove', handleMouseMove3);
    document.removeEventListener('mouseup', handleMouseUp3);
  };
  

  const handleTouchStart2 = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove2 = (e) => {
    const deltaX = e.touches[0].clientX - startX;
    setCurrentRotation(currentRotation + deltaX);
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd2 = () => {
    // Handle touch end if needed
  };


  return (

    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
       <div
        className="product-view"
        onMouseDown={handleMouseDown3}
        onTouchStart={handleTouchStart2}
        onTouchMove={handleTouchMove2}
        onTouchEnd={handleTouchEnd2}
      >
        <img
          src={Data.original_image}
          draggable="false"
          alt="Product"
          className="product-image"
          style={{
            transform: `rotateY(${currentRotation}deg)`,
          }}
        />
      </div>

    {/* <div
      className="product-view"
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleTouchEnd}
    >
      <img
        src={images[currentImageIndex]}
        alt="Product"
        draggable="false"
        style={{
          cursor: 'grab',
        }}
      />
    </div> */}
    </div>
    
  );
};

export default Product360;
