import React from 'react';
import { Link } from "react-router-dom";
import logo from '../../imgs/logo/logo.png';
import payment from '../../imgs/visa_list.png';
import playstore from '../../imgs/android.png';
import itunes from '../../imgs/itunes.png';


const BigFooter = () => {
  return (
    <footer className="desktop_footer">
     <div className="container">
       <div className="row">

       <div className="col-3 footer-contact">
          
          <p className="font-19 golden-color mb-0"><b>CONTACT US</b></p>
          <p className="text-golden mt-10 footer-contact">
                   <a href='#' className="text-golden">
                    <i className="bi bi-location"></i> 
                    <i className="bi bi-geo-alt"></i> 
                      Miac-2436, Plot No. 2435, Mie Part A,<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bahadurgarh - 124507, Haryana, India
                   </a>
                  
                    <br /><br />
                    <a tel="+919950039573" className="text-golden">
                    <i className="bi bi-phone"></i> +91-9354 748327
                    </a> <br /> <br />
                    
                    <a href="mialto:info@ndfurnituremart.com" className="text-golden">
                    
                    <i className="bi bi-envelope"></i>   info@ndfurnituremart.com
                    </a>
          </p>
        </div>
       

        <div className="col-3 footer-contact">
          
          <p className="font-19 golden-color mb-0"><b>INFORMATION</b></p>
          <p className="text-golden mt-10 footer-link-box">
            <Link to="../story" className="text-golden">About Company</Link><br />
            <Link to="../contact" className="text-golden">Contact Us</Link><br />
            <Link to="../terms" className="text-golden">Terms & Conditions</Link><br />
            <Link to="../privacy" className="text-golden">Privacy Policy</Link>
        
          </p>
        </div>
        <div className="col-3 footer-contact">
          
          <p className="font-19 golden-color mb-0"><b>MY ACCOUNT</b></p>
          <p className="text-golden mt-10 footer-link-box">
            <Link to="../account" className="text-golden mt-10">My Account</Link><br />
            <Link to="../PendingOrders" className="text-golden mt-10">Pending Orders</Link><br />
            <Link to="../OrderHistory" className="text-golden mt-10">Orders History</Link><br />
            <Link to="../addressBook" className="text-golden mt-20">Address Book</Link><br />
            <Link to="../editProfile" className="text-golden mt-20">Account Details</Link>
          </p>
         
          
        </div>
       
        <div className="col-3 footer-contact">
          <div className="row p-0">
          <p className="font-19 golden-color mb-0 p-0"><b>OUR NEWSLETTER</b></p>
          <br />
          <p className='text-dark mt-10 mb-0  ml--10'>Signup today for free and be the first to get notified on new updates.</p>
          <input type="text" placeholder="Your Email" className="subscribe-box mt-10" />
          <button className="btn btn-primary mt-10">Submit</button>

          </div>
         
         
        </div>
      </div>
      <div className="divider mtb-10"></div>
      <div className="row d-flex align-items-center">
        <div className="col-4">
         <a href="https://play.google.com/store/apps/details?id=com.ndcp.app">
          <img src={playstore} alt="" className="img-app-icon" />
          </a>
          <img src={itunes} alt="" className="img-app-icon" />
        </div>
        <div className="col-4 text-center">
           <p className="mtb-0 text-golden font-11">
            Copyright &copy; 2022 Nd Furniture Mart</p>
        
        </div>
        <div className="col-4">
          <img src={payment} alt="" className="img-fluid float-right pay-links" />
        </div>
      </div>
    </div>
    </footer>
  )
}

export default BigFooter