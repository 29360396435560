import React from 'react'
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";

const Terms = () => {
  return (
    <div>
    <Header />
    <section className="mh-70">
      <div className="container mb-30">
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="mt-100">Privacy Policy</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
          <p className="font-16 text-description mt-20">

<br />
<b>1. Information We Collect:</b>
<br /><br />
1.1 Personal Information: When you use Google Play Store, we may collect certain personal information such as your name, email address, phone number, and billing information. We collect this information to provide you with a personalized and enhanced experience.
<br /><br />
1.2 Usage Information: We may collect information about your usage of the Google Play Store, including the apps you download, your device information (such as device model, operating system version, and unique device identifiers), IP address, and browsing history.
<br /><br />
1.3 Cookies and Similar Technologies: We use cookies and similar technologies to enhance your experience on the Google Play Store. These technologies enable us to collect information about your preferences and customize your experience accordingly.
<br /><br /><br />
<b>2. How We Use Your Information:</b>
<br /><br />
2.1 Providing Services: We use the information we collect to provide, maintain, and improve the Google Play Store services, personalize your experience, and deliver relevant content and recommendations.
<br /><br />
2.2 Communication: We may use your contact information to communicate with you about updates, new features, and promotional offers related to the Google Play Store. You can opt-out of these communications at any time.
<br /><br />
2.3 Analytics and Research: We may analyze the collected information to understand user behavior, preferences, and trends to improve our services, troubleshoot issues, and conduct research and analysis.
<br /><br />
2.4 Legal Compliance: We may use your information to comply with applicable laws, regulations, legal processes, or enforce our rights and protect against fraud or other illegal activities.
<br /><br />
<b>3. Information Sharing:</b>
<br /><br />
3.1 Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating, managing, and enhancing the Google Play Store. These service providers are obligated to maintain the confidentiality and security of your information.
<br /><br />
3.2 Legal Requirements: We may share your information in response to a legal request, court order, or government investigation. We may also share your information if we believe it is necessary to protect our rights, safety, or the rights, safety, or security of others.
<br /><br />
3.3 Aggregated or De-Identified Data: We may share aggregated or de-identified data with third parties for analytics, research, or other purposes. This data does not personally identify you.
<br /><br />
<b>4. Data Security:</b>
<br /><br />
We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.
<br /><br />
<b>5. Children's Privacy:</b>
<br /><br />
Google Play Store is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected personal information from a child under 13, we will take steps to delete the information as soon as possible.
<br /><br />
<b>6. Changes to this Privacy Policy:</b>
<br /><br />
We may update this Privacy Policy from time to time. Any changes will be effective upon posting the revised Privacy Policy on the Google Play Store. We encourage you to review this Privacy Policy periodically for any updates.
<br /><br />
<b>7. Contact Us:</b>
<br /><br />
If you have any questions, concerns, or suggestions regarding this Privacy Policy or our privacy practices, please contact us at info@ndfurnituremart.com.
<br /><br />
By using the Google Play Store, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your personal information as described herein.

 
</p>

          </div>
        </div>
        
      </div>
    </section>
    <FooterDesktop />
  </div>
  )
}

export default Terms