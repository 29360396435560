import React from "react";
import ProductCard from "../categories/ProductCard";

const YouMayLike = ({ products }) => {
 
  return (
    <>
      <div className="row">
        <div className="col-12 text-center mt-30">
          <h1>Related Products</h1>
        </div>
      </div>
      <br />
      <div className="row related_product">
        {products.map((product) => {
          return <ProductCard key={product.product_id} product={product} />;
        })}
      </div>
    </>
  );
};

export default YouMayLike;
