import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import { Link } from "react-router-dom";
import { CatalogContext, UserContext } from "../../contexts";
import Loading from "../common/Loading";
import httpService from "../../services/httpService";

const Login = () => {
  const [otp, setOtp] = useState();
  const { login, log, user } = useContext(UserContext);
  const { showToast } = useContext(CatalogContext);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    telephone: "",
    password: "",
  });

  useEffect(() => {
    if (log === 1) {
      console.log("here");
      navigate("/account");
    }
  }, [user, log]);

  const handleChange = (e) => {
    const form = { ...loginForm };
    form[e.target.name] = e.target.value;
    setLoginForm(form);
  };

  const loginUser = async () => {
    setButtonsDisabled(true);
    if (Number(loginForm.password) !== Number(otp)) {
      showToast("error", "Incorrect Otp", "");
      setButtonsDisabled(false);
      return false;
    }
    const keys = Object.keys(loginForm);
    for (let i = 0; i < keys.length; i++) {
      if (loginForm[keys[i]] === "") {
        showToast("error", `${keys[i]} is required!`, "");
        setButtonsDisabled(false);
        return false;
      }
    }

    const response = await login({ telephone: loginForm.telephone });
    console.log("response", response);
    if (response && "success" in response && response.success === 1) {
      console.log("user logged in", loginForm);
      showToast("success", "user logged in Successfully!", "");
      navigate("/account");
    } else {
      setButtonsDisabled(false);
      console.log("login failed!", response);
      showToast("error", response.error[0], "");
    }
  };

  const getOtp = () => {
    setButtonsDisabled(true);
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append("mobile_number", loginForm.telephone);
        const { data: response } = await httpService.post(
          httpService.apiEndpoint + "feed/rest_api/get_otp",
          formData,
          { headers: { ...httpService.headers } }
        );
        if (response && "success" in response && response.success === 1) {
          console.log(response.otp);
          setOtp(response.otp);
          setButtonsDisabled(false);
        } else {
          showToast("error", "Please try again later!", "");
          setButtonsDisabled(false);
        }
      } catch (error) {
        showToast("error", "Please try again later!", "");
        setButtonsDisabled(false);
      }
    };
    if (loginForm.telephone && loginForm.telephone.length >= 10) {
      fetchData();
    } else {
      showToast("error", "Invalid mobile number", "");
    }
    setButtonsDisabled(false);
  };
  return (
    <>
      <Header />
      {log === 0 ? (
        <div>
          <div className="section mh-70">
            <div className="row">
              <div className="col-12">
                {!otp ? (
                  <div className="login-card text-center login-box">
                    <h1>Login</h1>
                    <p className="text-description mb-0">
                      Login to access your previous orders.
                    </p>
                    <input
                      onChange={(e) => handleChange(e)}
                      type="test"
                      className="form-control "
                      placeholder="Mobile Number"
                      value={loginForm.telephone}
                      name="telephone"
                    />
                    <div>
                      <button
                        disabled={buttonsDisabled}
                        className="btn btn-primary btn-gold w-100 mt-20 mb-10"
                        onClick={getOtp}
                        style={{ width: "100%" }}
                      >
                        {buttonsDisabled ? "Loading..." : "Send OTP"}
                      </button>
                    </div>
                    <br />
                    New to our website?{" "}
                    <Link to="../register" className="text-description">
                      Register Now
                    </Link>
                    <br />
                    <Link to="../forget" className="text-description">
                      Forgot Password
                    </Link>
                  </div>
                ) : (
                  <div className="login-card text-center login-box">
                    <i
                      onClick={() => {
                        setOtp(undefined);
                        setLoginForm({ ...loginForm, password: "" });
                      }}
                      className="bi bi-arrow-left"
                    ></i>
                    <h1>Login</h1>
                    <p className="text-description mb-0">
                      Login to access your previous orders.
                    </p>
                    <input
                      onChange={(e) => handleChange(e)}
                      name="password"
                      type="password"
                      className="form-control "
                      placeholder="OTP"
                      value={loginForm.password}
                    />
                    <div>
                      <button
                        disabled={buttonsDisabled}
                        className="btn btn-primary btn-gold w-100 mt-20 mb-10"
                        onClick={loginUser}
                        style={{ width: "100%" }}
                      >
                        {buttonsDisabled ? "Loading..." : "Login"}
                      </button>
                    </div>
                    <br />
                    New to our website?{" "}
                    <Link to="../register" className="text-description">
                      Register Now
                    </Link>
                    <br />
                    <Link to="../forget" className="text-description">
                      Forgot Password
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default Login;
