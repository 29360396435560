import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {  SessionContext } from "../../contexts";
function hideSidebar() {
  document.getElementById("sidebar-bg").style.display = "none";
  document.getElementById("side-bar").style.left = "-270px";
}

const SideBar = () => {
  const { currency, setCurrency } = useContext(SessionContext);
  return (
    <>
      <div id="sidebar-bg" onClick={() => hideSidebar()}></div>
      <div id="side-bar">
        <i
          onClick={() => hideSidebar()}
          className="bi bi-x font-22 text-gold"
          id="close-sidebar"
        ></i>
       <nav className="mobile_nav">
  <a className="golden-color" href="/categories/87">
  Adjustable Handle (1D,2D,3D)
  </a>
  <hr />
  <a className="golden-color" href="/categories/73">
  Cafeteria Accessories
  </a>
  <hr />
  <a className="golden-color" href="/categories/83">
  Chair Base
  </a>
  <hr />
  <a className="golden-color" href="/">
  Chair Bushes, Shoes, Cap
    <hr />
    <span />
  </a>

  <a className="golden-color" href="/categories/88">
    Fixed Handle
  </a>
  <hr />
  <a className="golden-color" href="/categories/81">
  Institutional Furniture Parts
  </a>
  <hr />
  <a className="golden-color" href="/categories/77">
   Net Back
  </a>
  <hr />
  <a className="golden-color" href="/categories/85">
  PU Foam
  </a>
  <hr />
  <a className="golden-color" href="/categories/71">
  PU Pad & PP Pad
  </a>
  <hr />
  <a className="golden-color" href="/categories/84">
  Seat Cover & Accessories
  </a>
  <hr />
  
  <a className="golden-color" href="/categories/75">
  Trending Products
  </a>
  <hr />
  
  
 
  
</nav>

           &nbsp;&nbsp;&nbsp; <select
              value={currency}
              onChange={(e) => {
                setCurrency(e.target.value);
              }}
              className="currency_drop "
            >
              <option value="INR">INR</option>
              <option value="USD">USD</option>
            </select>
      </div>
    </>
  );
};

export default SideBar;
