import React, { useState, useContext, useEffect } from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import httpService from "../../../services/httpService";
import { CatalogContext, SessionContext, UserContext } from "../../../contexts";
import Loading from "../../common/Loading";

const OrderInfo = () => {
  const [order, setOrder] = useState();
  const { sessionId } = useContext(SessionContext);
  const { log } = useContext(UserContext);
  const params = useParams();
  const { getProductFromProductsList, showToast } = useContext(CatalogContext);
  const navigate = useNavigate();

  useEffect(() => {
    const getOrderDetails = async () => {
      try {
        const { data: orderDetails } = await httpService.get(
          httpService.apiEndpoint +
            "rest/order/orders&store_id=0&id=" +
            params.id,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        console.log(orderDetails);
        if (
          orderDetails &&
          "success" in orderDetails &&
          orderDetails.success === 1
        ) {
          setOrder(orderDetails.data);
        } else {
          showToast("error", orderDetails.error[0], "");
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          showToast("error", error.response.data.error[0], "");
        } else {
          showToast("error", "Unable to fetch your order details!", "");
        }
        navigate("/OrderHistory");
      }
    };
    console.log("order detail efc called", sessionId, params.status);
    if (sessionId && log === 1) {
      getOrderDetails();
    }
  }, [sessionId, log]);
  return (
    <div>
      <Header />
      {log === 1 && order ? (
        <>
          <div className="section mh-70">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <h2 className="mt-120">Order Information</h2>
                  <Link
                    to="../account"
                    className="back_btn text-description fl"
                  >
                    {" "}
                    <i className="bi bi-arrow-left-circle-fill"></i> Go Back
                  </Link>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-8 offset-sm-2 col-12 offset-0">
                  <table className="table table-bordered table-hover mt-30">
                    <thead>
                      <tr>
                        <td className="text-left" colSpan={2}>
                          Order Details
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left" style={{ width: "50%" }}>
                          {" "}
                          <b>Order ID:</b> {order.order_id}
                          <br />
                          <b>Date Added:</b>{" "}
                          {order.date_added.substr(
                            order.date_added.indexOf(",") + 1,
                            13
                          )}
                        </td>
                        <td className="text-left" style={{ width: "50%" }}>
                          {" "}
                          <b>Payment Method:</b> Cash On Delivery
                          <br />
                          <b>Shipping Method:</b> Free Shipping{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <td
                          className="text-left"
                          style={{ width: "50%", verticalAlign: "top" }}
                        >
                          Payment Address
                        </td>
                        <td
                          className="text-left"
                          style={{ width: "50%", verticalAlign: "top" }}
                        >
                          Shipping Address
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left">
                          {order.payment_firstname} {order.payment_lastname}
                          <br />
                          {order.payment_company}
                          <br />
                          {order.payment_address_1}
                          <br />
                          {order.payment_city} {order.payment_postcode}
                          <br />
                          {order.payment_zone}
                          <br />
                          {order.payment_country}
                        </td>
                        <td className="text-left">
                          {order.shipping_firstname} {order.shipping_lastname}
                          <br />
                          {order.shipping_company}
                          <br />
                          {order.shipping_address_1}
                          <br />
                          {order.shipping_city} {order.shipping_postcode}
                          <br />
                          {order.shipping_zone}
                          <br />
                          {order.shipping_country}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <td className="text-left">Product Name</td>
                          <td className="text-left">Model</td>
                          <td className="text-right">Quantity</td>
                          <td className="text-right">Price</td>
                          <td className="text-right">Total</td>
                        </tr>
                      </thead>
                      <tbody>
                        {order.products.map((product) => {
                          return (
                            <tr>
                              <td className="text-left"> {product.name} </td>
                              <td className="text-left wrapped-up">
                                {product.model}
                              </td>
                              <td className="text-right">{product.quantity}</td>
                              <td className="text-right">{product.price}</td>
                              <td className="text-right">{product.total}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={3} />
                          <td className="text-right">
                            <b>Sub-Total</b>
                          </td>
                          <td className="text-right">
                            ₹
                            {parseFloat(
                              order.totals.find((total) => {
                                return total.code === "sub_total";
                              }).value
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} />
                          <td className="text-right">
                            <b>Free Shipping</b>
                          </td>
                          <td className="text-right">
                            {" "}
                            ₹ ₹
                            {parseFloat(
                              order.totals.find((total) => {
                                return total.code === "shipping";
                              }).value
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3} />
                          <td className="text-right">
                            <b>Total</b>
                          </td>
                          <td className="text-right">
                            ₹
                            {parseFloat(
                              order.totals.find((total) => {
                                return total.code === "total";
                              }).value
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div className="buttons clearfix">
                    <div className="pull-right">
                      <Link to="/account">
                        <button className="btn btn-primary btn-gold">
                          Continue
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </>
      ) : (
        <Loading />
      )}

      <FooterDesktop page="/profile" />
    </div>
  );
};

export default OrderInfo;
