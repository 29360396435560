import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import CityContext from "../../contexts/CityContext";
import { CartContext } from "../../contexts";



const CityCard = ({ City,handelCityClose }) => {
  const location=useLocation();
  const CityData=localStorage.getItem("City");
  const {SelectedCity,setSelectedCity} =useContext(CityContext);
  const {setCart,emptyCart}=useContext(CartContext);
  const [ReturnPath,setReturnPath]=useState("/home")
// console.log(" Return Path",CityData ? location.state.ReturnPath : "/home");
const navigate = useNavigate();

const handelCityClick=(e)=>{
  let SelectedCity=e.target.innerText;
  if(SelectedCity!="") {
      // console.log("SelectedCity",SelectedCity)
      localStorage.setItem("City",City.store_comment)
      localStorage.setItem("CityImage",City.store_image)
      localStorage.setItem("storeId",City.store_id)
      localStorage.setItem("SelectedStoreData",JSON.stringify(City));
      
      
      
      emptyCart();
      // setCart(undefined);
      setSelectedCity(City);
      handelCityClose()
      navigate("/home")
      window.location.reload();

  }
  // if(SelectedCity=="") console.log("SelectedCity empty","Null values");
  // return <Navigate to={ReturnPath}/>
}

useEffect(()=>{
  // const ReturnPathData= CityData  ? location.state.ReturnPath : "/home";
  // // console.log("ReturnPathData",ReturnPathData)
  // setReturnPath(ReturnPathData);
  
 },[])   
  return (
    
      <div className="card card-style mt-3" onClick={handelCityClick} style={{borderRadius:"20px",border:"1px solid red"}}>
        <div className="content mx-2">
          <div className="d-flex" style={{ alignItems: "center" }}>
            <div className="col-3">
              {
                City &&  <img
                alt="service"
                src={City.store_image}
                className="img-fluid rounded-m"
                style={{ maxWidth: "80px" }}
              />
              }
              
            </div>
            <div className="col-9 p-10">
              <h4 className="font-16 font-800 pt-2 mb-0">{City && City.store_comment}</h4>
              {/* <p className="font-11 line-height-m mt-0 mb-2 text-justify">
                {City.description.slice(0, 100)}...
              </p> */}
              <i className="bi bi-arrow-right-circle-fill category_list_arr mt-20"></i>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default CityCard;
