import React from "react";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import ss from "./ss.jpeg";
import shop from "./jkk.jpeg";
import para from './para.jpg'

const Story = () => {
  return (
    <div>
      <Header />
      <section className="dark-bg about-section ptb-30">
        <div className="container">
          <br />
          <br />
          <br />
          <br />
          <div className="row d-flex align-items-center ">
            <div className="col-sm-5 col-12">
              <img src={ss} alt="" className="img-fluid" />
            </div>
            <div className="col-sm-5 col-12">
              <h1 className="mt-30">Our Journey</h1>
              
              <p className=" mt-10 font-16">
            
              Mr. Narayan Dass Tayal, Founder and Managing Director, is the force behind our company. The sheer passion drives him. He envisaged the rise of India’s working human capital way back in 2002, the turn of the century, and left his well-paying job to start his manufacturing business of supplying ergonomic chair parts to the leading exporters in the furniture business.
<br /><br />
He aims to ‘provide a well-designed ergonomic chair to every student and professional at affordable cost.’ He believes that health is not a luxury but a fundamental right of all individuals.
<br /><br />
His empathy stems from the pain; he witnessed his friend endure the pain due to the wrong posture at work and decided that he would not let that happen to anyone else.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section parallax">
        <div style={{backgroundImage: "url(" + para + ")"}} className="para-div section">
          <div className="container">
            <div className="row">
              <div className="col-10 offset-sm-1">
                <br />
                <br />
                <br />
                <h1 style={{ fontSize: 34 }}>
                  GENESIS OF BRAND ND: How it all began
                </h1><br />
                <p className="font-16 dark-text">
                <b>“Everyone has some story to inspire. In my case, it is my friend’s pain and agony.” – Mr. Narayan Dass (Founder & MD)
<br /><br />
Watching his friend in deep agony with severe back pain and leg problems was a defining moment for Mr. Das, the Founder of this company. The day-long sitting hours on an inappropriate chair and the uncomfortable sitting position gave him severe back and leg pain. Witnessing his rehabilitation back to everyday life without pain, Mr. Das committed himself to the cause of making the workforce in India comfortable and pain-free. 
<br /><br />
Thus began his journey to manufacture high-quality, ergonomic, and economical working chair parts. 
<br /><br />
Today, his life’s calling is to make comfortable and ergonomic working chairs accessible to India’s millions of people in the workforce, who spend the most significant chunk of their day at work at the lowest price possible.
</b> </p>
                <br />
            
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="dark-bg about-section ptb-30">
        <div className="container">
          <br />
          <br />
          <br />
          <div className="row d-flex align-items-center ">
          <div className="col-sm-7 col-12">
              <h1 className="mt-10">FUTURE IMPRINTS</h1>
              <p className=" mt-10 font-16">
              The company has a significant market value and caters to the needs of various industries across PAN India. Under the supervision of talented minds, the company plans to expand its wings across the globe. We try to expand our company nationally and globally and reach 1000 crore by 2030. 
<br /><br />
 

Currently, we aim to enter into the B2C market after catering to the needs of the B2B market. The company’s experts are working day and night to achieve their goals. By April 2022, the nerds of the company will launch the B2B Mobile APP, which is the first of its kind. 
              </p>
            </div>
            <div className="col-sm-5 col-12">
              <img src={shop} alt="" className="img-fluid" />
            </div>
            
          </div>
        </div>
      </section>

      <FooterDesktop />
    </div>
  );
};

export default Story;
