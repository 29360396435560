import React from 'react'
import { Link } from 'react-router-dom';
import shop from './ccs.png';


const Behind = () => {
  return (
   
        <div className="container our-story-section">
            <div className="row d-flex align-items-center">
              
            <div className="col-sm-6 col-12 text-center">
  <br />
  <img
    src={shop}
    alt=""
    className="img-fluid story-img"
  />
</div>

                <div className="col-12 col-sm-6 ourstory-text">
  <br />
  <h2 className="color-prime mt-10">Experts In Manufacturing Plastic Chair Parts</h2>
  <p>
  With our high-tech manufacturing facility and delivery service, we have impacted more than 1130+ customers across the 23 states in India.
<br /><br />
Since 2002, we have helped over 607 customers to generate revenue of 3750 crores and profit of 750 crores. Office furniture manufactured from our parts is used in 9 countries.
<br /><br />
we have high tech and largest manufacturing unit through which we manufacture 565000 units/month. In the last 20 years, we have successfully manufactured and delivered 10 crores 17 Lakh 532 Products in 23 states in India.
<br /><br />
We have helped our customers to fulfil the order for SAMSUNG, AMERICAN EXPRESS, LG, VOLVO, HYUNDAI, INFOSYS, FORTIS, TITAN, BOSH, and many more.
<br /><br />
We offer a wide range of plastic chair parts, chair shoes, chair base, chair handle, chair cap, inner/outer shell, chair writing pad, and Godrej chair fittings.
<br /><br />
ND is committed to delivering the aesthetics and comfort of well-designed furniture and therefore, all our products have been moulded using cutting-edge technology and the latest machinery in precision.
  </p>
  <Link to="/story">
    <button className="btn btn-primary">
      About Us &nbsp;&nbsp;
      <i className="bi bi-arrow-right-circle-fill" />
    </button>
  </Link>
</div>
            </div>
        </div>
  
  )
}

export default Behind