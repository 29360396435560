import React from "react";
import ProductCard from "./ProductCard";
import Header from "../../common/Header";
import FooterDesktop from "../../common/FooterDesktop";
import { useEffect, useContext } from "react";
import http from "../../../services/httpService";
import { useParams } from "react-router-dom";
import { useState } from "react";
import Loading from "../../common/Loading";
import Curtain from "../../common/Curtain";
import { SessionContext } from "../../../contexts";

const Categories = () => {
  const { currency } = useContext(SessionContext);
  const [isLoaded, setLoaded] = useState(0);
  const [products, setProducts] = useState();
  const [categoryDetails, setCategoryDetails] = useState();
  const params = useParams();
  const store_id =localStorage.getItem("storeId");
  useEffect(() => {
    setLoaded(0);
    setTimeout(function () {
      setLoaded(1);
    }, 2000);
    setProducts(undefined);
    setCategoryDetails(undefined);
    const fetchData = async () => {
      try {
        const { data: fetchedProducts } = await http.get(
          http.apiEndpoint +
            "feed/rest_api/products&category="+params.id+"&store_id="+store_id,
          { headers: { ...http.headers, "X-Oc-Currency": currency } }
        );
        console.log("fetchedProducts", fetchedProducts);
        if (
          typeof fetchedProducts == "object" &&
          "success" in fetchedProducts &&
          fetchedProducts.success === 1
        ) {
          setProducts(fetchedProducts.data);
        }

        const { data: fetchedCategoryDetails } = await http.get(
          http.apiEndpoint +
            "feed/rest_api/categories&store_id=0&id=" +
            params.id,
          { headers: { ...http.headers, "X-Oc-Currency": currency } }
        );

        if (
          typeof fetchedCategoryDetails == "object" &&
          "success" in fetchedCategoryDetails &&
          fetchedCategoryDetails.success === 1
        ) {
          setCategoryDetails(fetchedCategoryDetails.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [params.id, currency]);
  return (
    <div>
      <Header />
      {!isLoaded && <Curtain />}
      <section style={{background:"#f8f8f8"}} className="section ptb-20 mh-70">
        <div className="container text-center">
          <div className="row">
            <div className="col-12 ">
              <h1 className="mt-100">
                {categoryDetails ? categoryDetails.name : ""}
              </h1>
            </div>
          </div>

          <div className="row">
            {products ? (
              products.length > 0 ? (
                products.map((product) => {
                  return (
                    <ProductCard key={product.product_id} product={product} />
                  );
                })
              ) : (
                <h6 className="text-center">coming soon!</h6>
              )
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Categories;
