import React, { useContext, useEffect } from "react";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import { Link, useNavigate } from "react-router-dom";

import i1 from "./icons/history.png";
import i2 from "./icons/pending.png";
import i3 from "./icons/edit.png";
import i4 from "./icons/address.png";
import i5 from "./icons/wishlist.png";
import i6 from "./icons/reset.png";
import { UserContext } from "../../contexts";
import Loading from "../common/Loading";

const Account = () => {
  const { user, logout, userDetails, log } = useContext(UserContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (log == 0) {
      navigate("/login");
    }
  }, [user, log]);

  return (
    <>
      <Header />
      {user && userDetails && log == 1 ? (
        <div>
          <div className="section mh-70">
            <div className="container ac-container">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="mt-120">
                    Hello {userDetails.firstname} {userDetails.lastname}
                  </h1>
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-sm-4 col-6 text-center">
                  <Link to="../OrderHistory">
                    <button className="btn btn-border ac-btn">
                      <img src={i1} alt="" className="img-fluid" />
                      <p className="text-gold mb-0 font-16"> Order History</p>
                    </button>
                  </Link>
                </div>

               
                <div className="col-sm-4 col-6 text-center">
                  <Link to="../editProfile">
                    <button className="btn btn-border ac-btn">
                      <img src={i3} alt="" className="img-fluid" />
                      <p className="text-gold mb-0 font-16">Edit Profile</p>
                    </button>
                  </Link>
                </div>

                <div className="col-sm-4 col-6 text-center">
                  <Link to="../addressBook">
                    <button className="btn btn-border ac-btn">
                      <img src={i4} alt="" className="img-fluid" />
                      <p className="text-gold mb-0 font-16">Address Book</p>
                    </button>
                  </Link>
                </div>

               
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    onClick={logout}
                    className="btn btn-primary btn-gold w-100"
                  >
                    {" "}
                    Logout
                  </button>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      ) : (
        <Loading />
      )}
      <FooterDesktop page="/profile" />
    </>
  );
};

export default Account;
