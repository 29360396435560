import { Box, Button, SwipeableDrawer } from '@mui/material'
import React, { useContext } from 'react'
import CityContext from '../../contexts/CityContext';
import { useEffect } from 'react';
import CityCard from './CityCard';
import { useState } from 'react';

export const SelectCity = () => {
    const {AllCities,AllCitiesData,SelectedCity}=useContext(CityContext);
    const [CityData, setCityData] = useState({
        cityName:localStorage.getItem("City"),
        cityImage:localStorage.getItem("CityImage")
      })
    const [Cpostate, setCpoState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

      const handelCityClose = (id,data)=>{
        setCpoState({ ...Cpostate, 'right': false });
        
    
      }

      const toggleDrawerCpo = (anchor, open) => (event) => {
        setCpoState({ ...Cpostate, [anchor]: open });
      };
    const listCityDetails = (anchor) => (
        <Box
          sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 , padding:"10px"}}
          role="presentation"
          
        >
          
        {AllCitiesData && AllCitiesData.length !== 0 ? (
                
                AllCitiesData.map((el) => {
                 
                    return (
                      <CityCard
                        City={el}
                        handelCityClose={handelCityClose}
                      />
                    );
                  
                })
              ) 
              : 
              (
                // <Empty />
                <h1>No city data prsent</h1>
              )}
    
            
    
            
    
    
        </Box>
      );

      useEffect(()=>{
          // console.log("REfresh toh banta h");
          setCityData({
            cityName:localStorage.getItem("City"),
        cityImage:localStorage.getItem("CityImage")
          })
      },[SelectedCity])
  return (
    <div>
        

          <div style={{height:"55px",width:"60px",textAlign:"center",marginTop:"10px"}} >
              <div style={{ borderRadius:"50px",width:"40px",height:"40px",margin:"auto"}}>
              <img style={{width:"30px",height:"30px"}} onClick={()=>{
            setCpoState({ ...Cpostate, 'right': true });
        }} src={CityData.cityImage} alt/>
              </div>
              <div style={{marginTop:"-15px"}}>
              <span style={{fontSize:"11px",fontWeight:"bold"}}>{CityData.cityName}</span>
              </div>
             
            </div>
        <SwipeableDrawer
            anchor={'right'}
            open={Cpostate['right']}
            onClose={handelCityClose}
            onOpen={toggleDrawerCpo('right', true)}
          >
            {listCityDetails('right')}
          </SwipeableDrawer>
    </div>
  )
}
